// https://wiki.scn.sap.com/wiki/display/CRM/Brazil
// import {cpf, cnpj} from 'cpf-cnpj-validator'

const getBlacklist = len => {
  const blacklist = []
  for (let i = 0; i < len; i++) {
    blacklist.push(''.padStart(len, String(i)))
  }
  return blacklist
}

/***
 * https://crosstec.org/en/forums/3-breezingforms-for-joomla/66739-validation-and-mask-to-brazilian-document-cpf.html?start=6
 * @param {string} cpfValue CPF string
 * @returns {boolean} Is the string valid
 */
const validateCPF = cpfValue => {
  const BLACKLIST = getBlacklist(11)
  if (BLACKLIST.includes(cpfValue)) {
    return false
  }

  /**
   * @param {number[]} digits
   */
  const calculateChecksum = digits => {
    const multipliers = []
    for (let i = digits.length + 1; i >= 2; i--) {
      multipliers.push(i)
    }
    const calculatedIndexes = digits.map((digit, i) => digit * multipliers[i])
    const digitTotal = calculatedIndexes.reduce((sum, value) => sum + value, 0)
    const mod = (digitTotal * 10) % 11 % 10
    return mod
  }

  const cpfNums = cpfValue.split('').map(n => Number(n))
  const digits = cpfNums.slice(0, cpfNums.length - 2)
  const checksum = cpfNums.slice(cpfNums.length - 2)
  const firstDigit = calculateChecksum(digits)
  if (firstDigit !== checksum[0]) {
    return false
  }
  const secondDigit = calculateChecksum(digits.concat(firstDigit))
  if (secondDigit !== checksum[1]) {
    return false
  }
  return true
}

/***
 * https://metacpan.org/pod/Business::BR::CNPJ
 * @param {string} cnpj CNPJ string
 * @returns {boolean} Is the string valid
 */
const validateCNPJ = cnpjValue => {
  const BLACKLIST = getBlacklist(14)
  if (BLACKLIST.includes(cnpjValue)) {
    return false
  }

  const calculateChecksum = val => {
    const digits = val.split('').map(n => Number(n))
    const multiplier = (digits.length === 13 ? [5] : []).concat([6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9])
    const calculatedIndexes = digits.map((digit, i) => digit * multiplier[i])
    const digitTotal = calculatedIndexes.reduce((sum, value) => sum + value, 0)
    const mod = (digitTotal % 11) % 10
    return mod
  }

  const digits = cnpjValue.substring(0, cnpjValue.length - 2)
  const checksum = cnpjValue.substring(cnpjValue.length - 2).split('').map(n => Number(n))
  const firstDigit = calculateChecksum(digits)
  if (firstDigit !== checksum[0]) {
    return false
  }
  const secondDigit = calculateChecksum(digits.concat(firstDigit))
  if (secondDigit !== checksum[1]) {
    return false
  }
  return true
  // for (let i = 1; i >= 0; i--) {
  //   const arr = cnpjNums.slice(0, cnpjNums.length - i)
  //   if (!isValid(arr)) {
  //     return false
  //   }
  // }
  // return true
}

/***
 * @param {string} cpfCnpj CPF/CNPJ string
 * @returns {boolean} Is the string valid
 */
const validate = cpfCnpj => {
  if (!cpfCnpj) {
    return false
  }
  const onlyNumbers = /[^\d]/g
  const stripped = cpfCnpj.replace(onlyNumbers, '')
  if (stripped.length === 11) {
    return validateCPF(stripped)
  } else if (stripped.length === 14) {
    return validateCNPJ(stripped)
  } else {
    return false
  }
}

module.exports = {
  validate
}
