<template>
  <div id="page-checkout" data-synth="page-checkout" class="row xs-fill-white">
    <div
      class="form-section xs-border-gray-medium xs-text-gray-medium xs-pt-15 md-mb-10 md-border md-rounded md-shadow-down-1"
    >
      <span class="header-location">
        <img
          src="https://static.phdvasia.com/mx/icons/icon-location.svg"
          class="mr-10 opacity-50"
          width="13"
        />
        <span>
          <router-link to="/">
            <span
              data-synth="top-delivery-address"
              v-if="
                $disposition.getByKey(this, 'orderType', '') === $const.DELIVERY
              "
              v-html="
                $t('delivery_string_format', {
                  address: getDeliveryInfo(this).addressInfo
                })
              "
            ></span>
            <span v-else>
              <span v-if="$i18n.locale === $const.LANG_ES">
                <span class="t14">{{ $t('take_away') }}</span>
                <span class="order-type-text border-b bold" data-synth="top-collection-address">{{
                  getDeliveryInfo(this).addressInfo
                }}</span>
              </span>
              <span class="order-type-text border-b" v-else>
                <span class="t14">{{ $t('take_away') }}</span>
                <span class="border-b bold" data-synth="top-collection-address">{{
                  getDeliveryInfo(this).addressInfo
                }}</span>
              </span>
            </span>
          </router-link>
        </span>
      </span>
      <div class="clear"></div>
      <hr class="mt-15 mb-15" />
      <span class="header-time cursor-pointer">
        <img
          src="https://static.phdvasia.com/mx/icons/clock.svg"
          width="14"
          class="mr-10"
        />
        <span
          v-html="collectionTime"
          @click="modaltime()"
          class="order-datetime-text border-b"
        ></span>
        <span
          v-if="$disposition.getByKey(this, 'advanceOrder', 0) === 0"
          class="order-datetime-text border-b"
          >{{
            $t('checkout.waiting_time', { min: getWaitingTime(this) })
          }}</span
        >
        <span
          @click="modaltime()"
          class="btn-change pull-right border-b"
          style
          >{{ $t('button_change') }}</span
        >
      </span>
    </div>
    <div class="form-section text-center" v-if="!$auth.check()">
      <router-link to="/login?returnUrl=checkout">
        <span class="button button--primary">
          <span
            class="fa fa-user"
            style="width: auto; padding-right: 0.5em;"
          ></span
          >{{ $t('checkout.login') }}
        </span>
      </router-link>
    </div>
    <form @submit="checkOut" action method="post" novalidate="true">
      <div class="form-section">
        <div class="form-section__heading">
          <span>{{ $t('who_order') }}</span>
        </div>

        <div class="form-field">
          <div class="xs-flex xs-items-center xs-mb-5">
            <label class="label xs-mb-0" for="name">
              {{ $t('name') }}
              <span class="cl-red t20">*</span>
            </label>
            <span
              v-if="errors.firstName"
              class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
              style="display:block"
            >
              <span>{{ errors.firstName }}</span>
            </span>
          </div>
          <input
            id="name"
            ref="name"
            class="input xs-mb-10"
            v-bind:class="{
              'has-errors': errors.firstName,
              'has-success': errors.firstName === false
            }"
            name="firstName"
            data-synth="first-name"
            v-model="firstName"
            v-bind:placeholder="$t('checkout.first_name_placeholder')"
            maxlength="30"
            type="text"
          />
        </div>

        <div class="form-field">
          <div class="xs-flex xs-items-center xs-mb-5">
            <label class="label xs-mb-0" for="lastName">
              {{ $t('surname') }}
              <span class="cl-red t20">*</span>
            </label>
            <span
              v-if="errors.lastName"
              class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
              style="display:block"
            >
              <span>{{ errors.lastName }}</span>
            </span>
          </div>
          <input
            id="lastName"
            ref="lastName"
            class="input xs-mb-10"
            v-bind:class="{
              'has-errors': errors.lastName,
              'has-success': errors.lastName === false
            }"
            name="lastName"
            data-synth="last-name"
            v-model="lastName"
            v-bind:placeholder="$t('checkout.last_name_placeholder')"
            maxlength="30"
            type="text"
          />
        </div>

        <div class="form-field">
          <div class="xs-flex xs-items-center xs-mb-5">
            <label class="label xs-mb-0" for="phone">
              {{ $t('mobile') }}
              <span class="cl-red t20">*</span>
            </label>
            <span
              v-if="errors.phone"
              class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message error-text"
              error-textzstyle="display:block"
            >
              <span>{{ errors.phone }}</span>
            </span>
          </div>
          <input
            id="phone"
            ref="phone"
            :minlength="10"
            :maxlength="10"
            class="input xs-mb-10"
            v-bind:class="{
              'has-errors': errors.phone,
              'has-success': errors.phone === false
            }"
            name="phone"
            data-synth="phone"
            v-model="phone"
            v-bind:placeholder="$t('place_holder_phone')"
            type="text"
          />
        </div>

        <div class="form-field">
          <div class="xs-flex xs-items-center xs-mb-5">
            <label class="label xs-mb-0" for="email">
              {{ $t('email') }}
              <span class="cl-red t20">*</span>
            </label>
            <span
              v-if="errors.emailAddress"
              class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message error-text"
            >
              <span>{{ errors.emailAddress }}</span>
            </span>
          </div>
          <input
            id="email"
            ref="email"
            class="input xs-mb-10"
            v-bind:class="{
              'has-errors': errors.emailAddress,
              'has-success': errors.emailAddress === false
            }"
            v-bind:placeholder="$t('place_holder_email')"
            name="emailAddress"
            data-synth="email"
            v-model="emailAddress"
            type="email"
          />
        </div>

        <div v-if="
                    $disposition.getByKey(this, 'orderType', '') ===
                      $const.DELIVERY
        ">
          <div class="form-section__heading">
            <span>{{ $t('checkout.extra_infos') }}</span>
          </div>

          <div class="form-field">
            <p class="xs-pb-20">
              <img
                src="https://static.phdvasia.com/mx/icons/icon-location.svg"
                class="mr-10 opacity-50"
                width="13"
              />
              <span>
                <router-link to="/">
                  <span
                    data-synth="bottom-coldel-address"
                    v-html="
                      $t('delivery_string_format', {
                        address: getDeliveryInfo(this).addressInfo
                      })
                    "
                  ></span>
                  <div class="form-field mt-10">
                    <span v-html="$t('contact_less_delivery_checkout')">
                  </span>
                </div>
                </router-link>
              </span>
            </p>
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label xs-mb-0" for="interiorNumberCheck">{{
                $t('interior_number')
              }}
                <span class="cl-red t20">*</span>
              </label>
              <span
                v-if="errors.interiorNumberCheck"
                class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                style="display:block"
              >
                <span>{{ errors.interiorNumberCheck }}</span>
              </span>
            </div>
            <div class="pt-10">
              <label class="method-item xs-flex xs-items-center xs-mb-10 label-radio" data-synth="interior-number-check-sin-numero">
                <input
                  class="xs-block rounded xs-p-5 radio"
                  ref="interiorNumberCheck"
                  data-name="interior-number-check"
                  id="interior-number-check"
                  data-synth="interior-number-check"
                  name="interiorNumberCheck"
                  type="radio"
                  value="sin-numero"
                  v-model="interiorNumberCheck"
                />
                <i></i>
                <span class="xs-mr-auto xs-flex xs-items-center" style="font-weight: normal;">
                  Sin Numero
                </span>
              </label>
               <label class="method-item xs-flex xs-items-center xs-mb-10 label-radio">
                <input
                  class="xs-block rounded xs-p-5 radio"
                  ref="interiorNumberCheck"
                  data-name="interior-number-check"
                  id="interior-number-check"
                  data-synth="interior-number-check"
                  name="interiorNumberCheck"
                  type="radio"
                  value="con-numero"
                  v-model="interiorNumberCheck"
                />
                <i data-synth="interior-number-check-con-numero"></i>
                <span class="xs-mr-auto xs-flex xs-items-center w-full">
                  <input
                    id="interiorNumber"
                    ref="interiorNumber"
                    class="input"
                    v-bind:class="{
                      'has-errors': errors.interiorNumber && !interiorNumberDisabled,
                      'has-success': errors.interiorNumber == false && !interiorNumberDisabled,
                      'input-text-disabled': interiorNumberDisabled
                    }"
                    name="interiorNumber"
                    data-synth="interior-number"
                    v-model="interiorNumber"
                    v-bind:placeholder="$t('place_holder_interior_number')"
                    v-bind:disabled="interiorNumberDisabled"
                    maxlength="25"
                    type="text"
                  />
                </span>
              </label>
            </div>
          </div>

           <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label xs-mb-0" for="exteriorNumber">
                {{ $t('checkout.exterior_number') }}
              </label>
              <span
                v-if="errors.exteriorNumber"
                class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                style="display:block"
              >
                <span>{{ errors.exteriorNumber }}</span>
              </span>
            </div>
            <p>{{ $t('checkout.exterior_number_note') }}</p>
            <input
              id="exteriorNumber"
              ref="exteriorNumber"
              class="input xs-mb-10"
              v-bind:class="{
                'has-errors': errors.exteriorNumber,
                'has-success': errors.exteriorNumber === false
              }"
              name="exteriorNumber"
              data-synth="exterior-number"
              v-model="exteriorNumber"
              v-bind:placeholder="$t('localization.put_correct_address_number')"
              maxlength="7"
              type="text"
            />
          </div>

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label xs-mb-0" for="reference">{{
                $t('checkout.reference')
              }}</label>
              <span
                v-if="errors.reference"
                class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                style="display:block"
              >
                <span>{{ errors.reference }}</span>
              </span>
            </div>
            <input
              id="reference"
              ref="reference"
              class="input xs-mb-10"
              v-bind:class="{
                'has-errors': errors.reference,
                'has-success': errors.reference === false
              }"
              name="reference"
              data-synth="reference"
              v-model="reference"
              v-bind:placeholder="$t('place_holder_reference')"
              maxlength="40"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="form-section__heading">
          <span>{{ $t('how_pay') }}</span>
        </div>
        <div class="xs-mt-20">
          <label
            v-if="cardText != ''"
            class="method-item xs-flex xs-items-center xs-border xs-py-20 xs-px-10 rounded xs-mb-10 label-radio"
            :data-synth="'payment-method-' + cardText"
          >
            <input
              class="xs-block rounded xs-p-5 radio"
              data-name="non-save-credit"
              id="non-save-credit"
              data-synth="non-save-credit"
              name="TransactionTypeID"
              value="non-save-credit"
              type="radio"
              v-model="paymentMethod"
            />
            <i></i>
            <span class="xs-mr-auto typo-l5 xs-flex xs-items-center">
              <i class="xs-hide md-block xs-mr-10 icon-cash"></i>
              <span>{{ $t('credit_cart') }}</span>
            </span>
            <img
              src="https://static.phdvasia.com/mx/icons/card-icons.png"
              alt="Credit Card Logos"
              style="max-width: 100px;"
              class="xs-ml-auto"
            />
          </label>
          <label
            v-for="(pMethod, i) in paymentMethodList"
            class="method-item xs-flex xs-items-center xs-border xs-py-20 xs-px-10 rounded xs-mb-10 label-radio"
            :data-synth="'payment-method-' + pMethod.code"
            v-bind:class="{ 'xs-border-green': paymentMethod === pMethod.code }"
            v-bind:for="pMethod.code"
            v-if="filterPaymentMethods(pMethod.code)"
          >
            <input
              class="xs-block rounded xs-p-5 radio"
              v-bind:data-name="pMethod.code"
              v-bind:id="pMethod.code"
              v-bind:data-synth="pMethod.code"
              name="TransactionTypeID"
              v-bind:value="pMethod.code"
              type="radio"
              v-model="paymentMethod"
            />
            <i></i>
            <span class="xs-mr-auto typo-l5 xs-flex xs-items-center">
              <i :class="(pMethod.code === 'eftpos' || pMethod.code === 'credit') ? 'xs-hide md-block xs-mr-10 icon-card' : 'xs-hide md-block xs-mr-10 icon-cash'"></i>
              <span v-if="(pMethod.code === 'eftpos' || pMethod.code === 'credit') && cardText != ''"
                >{{ $t('by_card') }} {{ cardText }}</span
              >
              <span v-else>{{ pMethod.display_name }}</span>
            </span>
            <img
              v-bind:src="pMethod.image_url"
              alt="Credit Card Logos"
              style="max-width: 100px;"
              class="xs-ml-auto"
            />
          </label>
          <div v-if="paymentMethod == 'credit_card_online'">
            <span v-if="brasPagError">{{ brasPagError }}</span>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label xs-mb-0" for="cardNumber">
                  {{ $t('card_number') }}
                  <span class="cl-red t20">*</span>
                </label>
                <span
                  v-if="errors.cardNumber"
                  class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                  style="display:block"
                >
                  <span>{{ errors.cardNumber }}</span>
                  <i class="icon-error"></i>
                </span>
              </div>
              <input
                type="text"
                id="cardNumber"
                v-model="cardNumber"
                class="input xs-mb-10 bp-sop-cardnumber"
              />
            </div>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label xs-mb-0" for="cardHolderName">
                  {{ $t('card_name') }}
                  <span class="cl-red t20">*</span>
                </label>
                <span
                  v-if="errors.cardHolderName"
                  class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                  style="display:block"
                >
                  <span>{{ errors.cardHolderName }}</span>
                  <i class="icon-error"></i>
                </span>
              </div>
              <input
                type="text"
                id="cardHolderName"
                v-model="cardHolderName"
                class="input xs-mb-10 bp-sop-cardholdername"
              />
            </div>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label xs-mb-0" for="expiryDate">
                  {{ $t('card_expiry_date') }}
                  <span class="cl-red t20">*</span>
                </label>
                <span
                  v-if="errors.cardExpiry"
                  class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                  style="display:block"
                >
                  <span>{{ errors.cardExpiry }}</span>
                  <i class="icon-error"></i>
                </span>
              </div>
              <input
                type="text"
                id="expiryDate"
                v-model="cardExpiry"
                class="input xs-mb-10 bp-sop-cardexpirationdate"
              />
            </div>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label xs-mb-0" for="cardCVV">
                  {{ $t('card_cvv') }}
                  <span class="cl-red t20">*</span>
                </label>
                <span
                  v-if="errors.cardCVV"
                  class="xs-text-right typo-l7 xs-text-red xs-ml-auto input-message"
                  style="display:block"
                >
                  <span>{{ errors.cardCVV }}</span>
                  <i class="icon-error"></i>
                </span>
              </div>
              <input
                type="text"
                id="cardCVV"
                v-model="cardCVV"
                class="input xs-mb-10 bp-sop-cardcvv"
              />
            </div>
          </div>
          <div
            v-if="pontaPoint > 0"
            class="xs-flex flex-column xs-border xs-py-20 xs-px-10 rounded xs-mb-10"
          >
            <div>
              {{
                $t('ponta.point_format_display', {
                  currency: $const.CURRENCY,
                  point: pontaPoint
                })
              }}
            </div>
            <div class="xs-flex xs-items-center">
              <input
                class="rounded xs-p-5 input"
                id="ponta-point"
                type="text"
                v-model="pontaUse"
                style="width: 200px"
              />
              <img
                src="https://static.phdvasia.com/mx/icons/ph3_bnr-ponta.png"
                alt="ponta"
                style="max-width: 100px"
                class="xs-ml-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-section form-field">
        <div
          class="term-box"
          style="padding-left: 0 !important;padding-right: 0 !important;"
        >
          <label
            class="xs-mb-30 xs-block"
            for="marketing"
            data-synth="term"
            style="padding-left: 0 !important; padding-right: 0 !important;"
          >
            <input
              name="Term"
              ref="Term"
              v-model="term"
              id="marketing"
              class="checkbox"
              type="checkbox"
            />
            <span></span>
          </label>
          <span class="xs-items-center fw-normal text-black" style>
            {{ $t('term_1') }}
            <a class="underline" href="/terms" target="_blank">{{ $t('term_4') }}</a> &
            <a class="underline" href="/privacy" target="_blank">{{
              $t('term_2')
            }}</a>
            {{ $t('term_3') }}
          </span>
        </div>
        <button
          id="submit-checkout"
          data-synth="submit-checkout"
          class="button button--primary"
          v-bind:class="{ 'btn-checkout-disabled': !checkText() || processing }"
          v-bind:disabled="!checkText() || processing"
        >
          <span>
            <span>{{ $t('go_to_payment') }}</span>
            <span v-if="cart.total > 0" class="price-number"
              > ({{ $const.CURRENCY }}{{ formatNum(cart.total) }})</span
            >
          </span>
        </button>
      </div>
    </form>
  </div>
</template>
<style>
@import '../assets/css/checkout.css';
</style>
<script>
import { TheMask } from 'vue-the-mask'
import { validate } from '@/utils/cpf-cnpj'
const modaltime = () => import('@/components/popup/OrderASAP.vue')

var vm = null
export default {
  components: {
    TheMask
  },
  data() {
    let checkoutInfos = this.$session.get('checkoutInfos')
    let dispositionInfos = this.$disposition.get(this)
    let exteriorNumber = this.$session.get('exteriorNumber')
    this.isTakeawayLess = this.$disposition.getByKey(this, 'takeaWayLess', 0) == 1
    return {
      deliveryAddress: dispositionInfos ? dispositionInfos.locationName : '',
      pontaPoint: 0,
      pontaUse: '',
      paymentMethodList: [],
      processing: false,
      preferPayment: 0,
      user: {},
      firstName: checkoutInfos ? checkoutInfos.firstName : '',
      lastName: checkoutInfos ? checkoutInfos.lastName : '',
      kanaName: '',
      phone: checkoutInfos ? checkoutInfos.phone : '',
      interiorNumberDisabled:
        checkoutInfos ? (
            (checkoutInfos.interiorNumberDisabled == null || !checkoutInfos.interiorNumberDisabled === 'undefined') ? true : checkoutInfos.interiorNumberDisabled
          ) :
        true,
      interiorNumberCheck: checkoutInfos ? checkoutInfos.interiorNumberCheck : '',
      interiorNumber: checkoutInfos ? checkoutInfos.interiorNumber : '',
      reference: checkoutInfos ? checkoutInfos.reference : '',
      exteriorNumber: exteriorNumber,
      house: '',
      blockNo: '',
      landmark: '',
      cardText: '',
      cardUUid: '',
      company: '',
      emailAddress: checkoutInfos ? checkoutInfos.email : '',
      taxId: '',
      receipt_name: '',
      cardName: '',
      cardHolderName: '',
      cardNumber: '',
      cardExpiry: '',
      cardCVV: '',
      cardType: '',
      // paymentToken: '',
      brasPagError: '',
      errors: {
        emailAddress: '', // checkoutInfos ? '' :  this.$t('required_display'),
        taxId: '',
        phone: '', // checkoutInfos ? '' : this.$t('required_display'),
        house: '',
        firstName: '', // checkoutInfos ? '' : this.$t('required_display'),
        lastName: '', // checkoutInfos ? '' : this.$t('required_display'),
        city: '',
        landmark: '',
        state: '',
        street: '',
        propertyType: '',
        term: '',
        receipt_name: '',
        unit_no: '',
        note: '',
        cardHolderName: '',
        cardExpiry: '',
        cardCVV: '',
        cardNumber: '',
        interiorNumber: '', // checkoutInfos ? '' : this.$t('required_display'),
        interiorNumberCheck: '' // checkoutInfos ? '' : this.$t('required_display')
      },
      city: '',
      state: '',
      street: '',
      receipt_flag: '',
      PropertyType: 1,
      unit_no: '',
      paymentMethod: this.isTakeawayLess ? this.$const.PAYMENT_METHOD_CREDIT_ON_DELIVERY : this.$const.PAYMENT_METHOD_CASH_ON_DELIVERY,
      term: '',
      collectionTime: this.getTimePicker(this, true),
      note: '',
      inputs: {
        cart_uuid: '',
        collection_time: '',
        advance_order: '',
        outlet_id: 0,
        user_id: 0,
        name: '',
        email: '',
        phone: '',
        address: '',
        currency: '',
        order_type: 0,
        payment_method: 1,
        device_type: '',
        note: '',
        description: '',
        lat: '',
        long: '',
        zip_code: '',
        street_name: '',
        block_no: '',
        unit_no: '',
        level_no: '',
        landmark: '',
        name_place: '',
        receipt_name: '',
        town: '',
        city: '',
        prefecture: '',
        cardType: '',
        country: ''
      }
    }
  },
  created() {
    this.getPaymentMethodList(
      this.$disposition.getByKey(this, 'outletCode', '0')
    )
  },
  destroyed() {
    this.$root.$off('collectionTimeUpdate')
    this.$root.$off('cartUpdate')
  },
  mounted() {
    vm = this
    vm.$disposition.init(vm)
    vm.$cart.init(vm)
    if (vm.cart.uuid) {
      this.eventTrackingCheckout(vm)
    }
    this.$root.$on('cartUpdate', () => {
      vm.$cart.init(vm)
    })
    this.$root.$on('collectionTimeUpdate', () => {
      vm.collectionTime = vm.getTimePicker(vm, true)
    })
    if (this.$session.get('checkoutInfos')) {
      vm.validateForm()
    }
    let silentOrderPost = document.createElement('script')
    silentOrderPost.setAttribute(
      'src',
      'https://www.pagador.com.br/post/scripts/silentorderpost-1.0.min.js'
    )
    document.head.appendChild(silentOrderPost)
    this.eventTrackingPaymentMethodSelected(this.paymentMethod);
    this.eventTrackingShippingInfo(vm)
  },
  beforeMount() {
    this.$cart.init(this)
    this.getUserInfo()
    vm = this
  },
  methods: {
    getCookie(name) {
      var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
      return v ? v[2] : null
    },
    /**
     * get Payment Method
     * */
    getPaymentMethodList(outletCode) {
      let param = { outletCode: outletCode }
      this.axios.apiTenant.getPaymentMethod(param, function(response) {
        // console.debug(vm.preferPayment)
        if (response.data.data.items) {
          if (response.status === 200) {
            vm.paymentMethodList = response.data.data.items
            for (let i in vm.paymentMethodList) {
              if (vm.paymentMethodList[i].code === vm.preferPayment) {
                vm.paymentMethod = vm.paymentMethodList[i].code
              }
            }
          }
        }
      })
    },
    filterPaymentMethods(paymentMethodCode) {
      // FIXME: Enable this logic when BOYD requires cash not to be applicable for payment
      // if (this.isTakeawayLess && paymentMethodCode == 'cash') {
      //   return false
      // } else {
      //   return true
      // }
      return true
    },
    modaltime() {
      this.$modal.show(
        modaltime,
        { parentlink: this.getCurrentPath() },
        { class: '' }
      )
    },
    sendCardData(accessToken) {
      var vm = this
      var options = {
        accessToken: accessToken,
        onSuccess: function(e) {},
        onError: function(e) {
          var errorCode = e.Code
          var errorMessage = e.Text
        },
        onInvalid: function(e) {
          vm.cardValidationMessages(e)
        },
        environment: 'sandbox',
        language: 'PT'
      }
      bpSop_silentOrderPost(options)
    },
    checkOut(e) {
      e.preventDefault()
      var vm = this
      this.processing = true
      vm.checkForm()
      vm.persistCheckoutInfos()
    },
    validateForm(e) {
      if (e) e.preventDefault()

      let flag = false
      if (!this.firstName) {
        this.errors.firstName = this.$t('required_display')
        this.$refs.name.focus()
        flag = true
      } else {
        this.$refs.name.classList.add('has-success')
      }

      if (!this.lastName) {
        this.errors.lastName = this.$t('required_display')
        this.$refs.lastName.focus()
        flag = true
      } else {
        this.$refs.lastName.classList.add('has-success')
      }

      if (!this.phone) {
        this.errors.phone = this.$t('required_display')
        if (!flag) {
          this.$refs.phone.focus()
        }
        flag = true
      } else if (
        this.phone.length < this.$const.PHONE_MIN_LENGTH ||
        this.phone.length > this.$const.PHONE_MAX_LENGTH
      ) {
        this.errors.phone = this.$t('validate_invalid_phone')
        if (!flag) {
          this.$refs.phone.focus()
        }
        flag = true
      } else {
        this.$refs.phone.classList.add('has-success')
      }

      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
        if (!flag) {
          this.$refs.email.focus()
        }
        flag = true
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
        if (!flag) {
          this.$refs.email.focus()
        }
        flag = true
      } else {
        this.$refs.email.classList.add('has-success')
      }

      if (
        this.$disposition.getByKey(this, 'orderType', '') ===
        this.$const.DELIVERY
      ) {
        if (!this.house) {
          this.errors.house = this.$t('required_display')
          if (!flag) {
            this.$refs.house.focus()
          }
          flag = true
        }
      }
      if (this.paymentMethod === 'credit_card_online' && !this.cardHolderName) {
        this.errors.cardHolderName = this.$t('required_display')
        this.$refs.cardHolderName.focus()
        flag = true
      }

      if (this.paymentMethod === 'credit_card_online' && !this.cardNumber) {
        this.errors.cardNumber = this.$t('required_display')
        this.$refs.cardNumber.focus()
        flag = true
      }

      if (this.paymentMethod === 'credit_card_online' && !this.cardExpiry) {
        this.errors.cardExpiry = this.$t('required_display')
        this.$refs.cardExpiry.focus()
        flag = true
      }

      if (this.paymentMethod === 'credit_card_online' && !this.cardCVV) {
        this.errors.cardCVV = this.$t('required_display')
        this.$refs.cardCVV.focus()
        flag = true
      }

      if (!this.term) {
        this.errors.term = this.$t('required_display')
        if (!flag) {
          this.$refs.Term.focus()
        }
        flag = true
      }

      if (!this.interiorNumberCheck) {
        this.errors.interiorNumberCheck = this.$t('required_display')
        if (!flag) {
          this.$refs.interiorNumberCheck.focus()
        }
        flag = true
      }

      if (flag) {
        return false
      } else {
        this.checkOut(e)
      }
    },
    persistCheckoutInfos() {
      this.$session.set('checkoutInfos', {
        firstName: this.$sanitize(this.firstName),
        lastName: this.$sanitize(this.lastName),
        email: this.emailAddress,
        phone: this.phone,
        exteriorNumber: this.exteriorNumber,
        interiorNumberDisabled: this.interiorNumberDisabled,
        interiorNumberCheck: this.interiorNumberCheck,
        interiorNumber: this.interiorNumber,
        reference: this.reference
      })
    },
    checkForm(paymentToken) {
      const orderType = this.$disposition.getByKey(this, 'orderType', null)

      if (this.paymentMethod === 'non-save-credit') {
        this.cardUUid = ''
        this.paymentMethod = 'credit'
      }

      this.errors = {
        emailAddress: false,
        taxId: false,
        phone: false,
        house: false,
        firstName: false,
        lastName: false,
        interiorNumberCheck: false,
        interiorNumber: false,
        exteriorNumber: false,
        reference: false,
        city: false,
        landmark: false,
        state: false,
        street: false,
        propertyType: false,
        term: false,
        receipt_name: false,
        cardHolderName: false,
        cardExpiry: false,
        cardNumber: false,
        cardCVV: false
      }

      let cart = this.$cart.get(this)
      this.inputs = {
        cart_uuid: cart.uuid,
        collection_time: this.convertDateTimeFromTimezoneToUTC(
          this.$disposition.getByKey(this, 'timezone', ''),
          this.$disposition.getByKey(this, 'collectionTimeValue', '')
        ),
        advance_order: this.$disposition.getByKey(this, 'advanceOrder', 0) || 0,
        outlet_id: this.$disposition.getByKey(this, 'outletId', '0'),
        user_id: this.$auth.check() ? this.$auth.user().id : 0,
        name: this.firstName + ' ' + this.lastName,
        firstName: this.$sanitize(this.firstName),
        lastName: this.$sanitize(this.lastName),
        interiorNumber: this.interiorNumber,
        reference: this.reference,
        email: this.emailAddress,
        phone: this.phone,
        currency: this.$const.CURRENCY_CODE,
        order_type: this.$disposition
          .getByKey(this, 'orderType', 'D')
          .charAt(0),
        payment_method: this.paymentMethod,
        street_name: this.street,
        block_no: this.blockNo,
        unit_no: orderType === this.$const.DELIVERY ? (this.interiorNumber || '') : '',
        house_no: this.exteriorNumber ? this.exteriorNumber : this.house,
        tax_id: this.taxId,
        level_no: '',
        landmark: this.landmark,
        address_code: this.$disposition.getByKey(this, 'addressCode', ''),
        name_place: '',
        payment_token_uuid: this.cardUUid,
        receipt_flag: this.receipt_flag ? 1 : 0,
        receipt_name: this.receipt_flag ? this.receipt_name : '',
        town: this.street,
        city: this.city,
        prefecture: this.state,
        note: orderType === this.$const.DELIVERY ? (this.reference || '') : '',
        cardType: this.cardType,
        lat: this.lat,
        long: this.long,
        zip_code: this.zip_code,
        address: this.exteriorNumber ? `${this.street} ${this.exteriorNumber}, ${this.landmark ? `${this.landmark}, ` : ''}${this.city} ${this.zip_code}, ${this.country}` : this.address,
        referral_code: this.isTakeawayLess ? 'qr' : '',
        customize: {
          order_language: this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE
        }
      }
      vm.axios.apiOrder.create(
        this.inputs,
        function(response) {
          vm.processing = false

          try {
            vm.eventTrackingOrderPlaced(vm, {
              name: vm.firstName,
              phone: vm.phone,
              email: vm.emailAddress,
            })
          } catch {}

          if (
            vm.inputs.payment_method !==
              vm.$const.PAYMENT_METHOD_CASH_ON_DELIVERY &&
            response.data.data.item.payment_url
          ) {
            window.location = response.data.data.item.payment_url
          } else {
            vm.eventTrackingOrderSubmit(vm.cart.uuid)
            vm.$session.remove('cart')
            vm.$session.remove('disposition')
            vm.$router.push({
              name: 'tracker-order',
              params: { uuid: response.data.data.item.uuid }
            })
          }
        },
        function(data) {
          let layer = {
            id: vm.cart.uuid,
            code: 'checkout-err',
            type: 'checkout',
            message: 'card-invalid'
          }
          vm.eventTrackingOrderError(layer)
          vm.processing = false
        }
      )
    },
    getUserInfo: function() {
      let data = this.$auth.user()
      let disposition = this.$disposition.get(this)
      if (!this.empty(data)) {
        this.preferPayment = data.prefer_payment
        this.axios.apiCustomer.getProfile(response => {
          this.preferPayment = response.data.data.item.prefer_payment
        })
        this.emailAddress = data.email
        this.phone = data.phone

        this.firstName = this.$sanitize(data.first_name || '')
        this.lastName = this.$sanitize(data.last_name || '')
      }
      if (disposition) {
        this.address = disposition.locationName
        this.city = disposition.cityName || ''
        this.state = disposition.prefectureName || ''
        this.street = disposition.villeageName || ''
        if (disposition.st && disposition.st.length > 0) {
          this.street += ' ' + disposition.st
        }
        this.landmark = disposition.landmark || ''
        this.house = disposition.houseNo || ''
        this.unit_no = disposition.unitNo || ''
        this.lat = disposition.delivery_lat || (disposition.lat || 0)
        this.long = disposition.delivery_lng || (disposition.long || 0)
        this.zip_code = disposition.zip_code || 0
        this.country = disposition.country || ''
      }
    },
    cardValidationMessages(validations) {
      var cardFields = {
        'bp-sop-cardholdername': 'cardHolderName',
        'bp-sop-cardnumber': 'cardNumber',
        'bp-sop-cardexpirationdate': 'cardExpiry',
        'bp-sop-cardcvv': 'cardCVV'
      }
      for (var i = 0; i < validations.length; i++) {
        var field = validations[i].Field
        this.errors[cardFields[field]] = validations[i].Message
      }
      return false
    },
    checkText() {
      if (
        this.getDeliveryInfo(this).orderType.toUpperCase() ===
          this.$const.DELIVERY &&
        this.emailAddress &&
        this.firstName &&
        this.lastName &&
        this.phone &&
        this.interiorNumberCheck &&
        (this.interiorNumberCheck === 'sin-numero' || this.interiorNumberCheck === 'con-numero' && this.interiorNumber.length > 0) &&
        this.term
      ) {
        return true
      } else if (
        this.getDeliveryInfo(this).orderType.toUpperCase() ===
          this.$const.CARRYOUT &&
        this.emailAddress &&
        this.firstName &&
        this.lastName &&
        this.phone &&
        this.term
      ) {
        return true
      } else {
        return false
      }
    },
    getOutletName() {
      let disposition = this.$disposition.get(this)
      return disposition.outletName
    }
  },
  watch: {
    emailAddress: function(newValue, oldValue) {
      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
      } else {
        this.errors.emailAddress = false
      }
    },
    taxId: function(newValue, oldValue) {
      if (!newValue) {
        this.errors.taxId = '' //this.$t('required_display')
      } else {
        if (!validate(newValue)) {
          this.errors.taxId = this.$t('validate_invalid_tax_id')
        } else {
          this.errors.taxId = false
        }
      }
    },
    firstName: function(newValue, oldValue) {
      if (!this.firstName) {
        this.errors.firstName = this.$t('required_display')
      } else {
        this.errors.firstName = false
      }
    },
    lastName: function(newValue, oldValue) {
      if (!this.lastName) {
        this.errors.lastName = this.$t('required_display')
      } else {
        this.errors.lastName = false
      }
    },
    interiorNumber: function(newValue, oldValue) {
      if (this.interiorNumber.length == 0) {
        this.errors.interiorNumber = this.$t('required_display')
      } else {
        this.errors.interiorNumber = false
      }
    },
    interiorNumberCheck: function(newValue, oldValue) {
      if (!this.interiorNumberCheck) {
        this.errors.interiorNumberCheck = this.$t('required_display')
      } else {
        this.errors.interiorNumberCheck = false
      }
      switch (this.interiorNumberCheck) {
        case 'sin-numero':
          this.interiorNumberDisabled = true
          this.interiorNumber = ''
          break;
        case 'con-numero':
          this.interiorNumberDisabled = false
          if (this.interiorNumber.length == 0) {
            this.errors.interiorNumber = this.$t('required_display')
          } else {
            this.errors.interiorNumber = false
          }
          break;
      }
    },
    reference: function(newValue, oldValue) {
      if (this.reference.length > 0) {
        this.errors.reference = false
      }
    },
    phone: function(newValue, oldValue) {
      var numbers = /^[-+]?[0-9]+$/
      var message = ''
      if (newValue && !newValue.match(numbers)) {
        this.$set(this, 'phone', oldValue)
        return
      }

      if (!this.phone) {
        message = this.$t('required_display')
      } else if (this.phone.length != this.$const.PHONE_MIN_LENGTH) {
        message = this.$t('validate_invalid_phone')
      } else {
        message = false
      }
      this.errors.phone = message
    },
    city: function(newValue, oldValue) {
      if (!this.city) {
        this.errors.city = this.$t('required_display')
      } else {
        this.errors.city = false
      }
    },
    house: function(newValue, oldValue) {
      if (!this.house) {
        this.errors.house = this.$t('required_display')
      } else {
        this.errors.house = false
      }
    },
    landmark: function(newValue, oldValue) {
      if (!this.street) {
        this.errors.street = this.$t('required_display')
      } else {
        this.errors.landmark = false
      }
    },
    unit_no: function(newValue, oldValue) {
      if (!this.street) {
        this.errors.street = this.$t('required_display')
      } else {
        this.errors.unit_no = false
      }
    },
    street: function(newValue, oldValue) {
      if (!this.street) {
        this.errors.street = this.$t('required_display')
      } else {
        this.errors.street = false
      }
    },
    state: function(newValue, oldValue) {
      if (!this.state) {
        this.errors.state = this.$t('required_display')
      } else {
        this.errors.state = false
      }
    },
    cardNumber: function(newValue, oldValue) {
      // visa, master, amex, ALELO for brazil
      var visa = new RegExp('^4')
      var amex = new RegExp('^3[47]')
      var master = new RegExp('^5[1-5]')
      var Elo = new RegExp(
        '^(636368)|(438935)|(504175)|(451416)|(636297)|(5067)|(4576)|(4011)'
      )
      if (this.cardNumber.match(visa)) {
        this.cardType = 'Visa'
      }
      if (this.cardNumber.match(amex)) {
        this.cardType = 'Amex'
      }
      if (this.cardNumber.match(Elo)) {
        this.cardType = 'Alelo'
      }
      if (this.cardNumber.match(master)) {
        this.cardType = 'Master'
      }
    },
    term: function(newValue, oldValue) {
      this.errors.term = ''
      if (!this.term) {
        this.errors.term = this.$t('required_display')
      }
    },
    receipt_name: function(newValue, oldValue) {
      this.errors.receipt_name = false
      if (this.receipt_flag && !this.receipt_name) {
        this.errors.receipt_name = this.$t('required_display')
      } else {
        this.errors.receipt_name = false
      }
    },
    receipt_flag: function(newValue, oldValue) {
      if (!this.receipt_flag) {
        this.errors.receipt_name = false
      }
    },
    paymentMethod: function(newValue, oldValue) {
      if (this.paymentMethod) {
        this.eventTrackingPaymentMethodSelected(this.paymentMethod);
      }
    }
  }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-text-disabled {
  background: #dddddd;
}
.btn-checkout-disabled {
  background: #e5edef;
}

.btn-checkout-disabled:hover {
  background: #e5edef;
  color: white !important;
  border: 1px solid white !important;
}
</style>
